import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { checkIsAuth } from "../redux/slices/auth";
import axios from "../utils/axios";
import Loader from "../components/Loader";
import Modal from "../components/Modal/Modal";
import Checkbox from "../components/Checkbox/Checkbox";
import "./UsersPage.css";

const UsersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const [modalDeleteActive, setModalDeleteActive] = useState(false);
  const [editUserModalActive, setEditUserModalActive] = useState(false);
  const [deleteUsersModalActive, setDeleteUsersModalActive] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userToBeUpdated, setUserToBeUpdated] = useState(null);
  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const [checked, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const navigate = useNavigate();
  const isAuth = useSelector(checkIsAuth);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(filteredUsers.map((li) => li.employee_id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const fetchUsers = useCallback(async () => {
    const { data } = await axios.get("/employee/list");

    setUsers(data);
    setFilteredUsers(data);
    setIsLoading(false);
  }, []);

  const sortUsers = (field1, field2 = false) => {
    filteredUsers.sort((a, b) => {
      let first = a[field1];
      let second = b[field1];

      if (field2) {
        first = a[field1][field2];
        second = b[field1][field2];
      }

      return sortDirection
        ? first > second
          ? 1
          : -1
        : first > second
        ? -1
        : 1;
    });

    setSortDirection(!sortDirection);
  };

  const filterUsers = (mask) => {
    const temp = users.filter((user) => {
      const username = `${user.last_name} ${user.first_name}`;

      return username.toLowerCase().includes(mask.toLowerCase());
    });

    setFilteredUsers(temp);
  };

  const submitHandler = () => {
    const queryData = {
      firstName: userFirstName,
      lastName: userLastName,
    };

    axios.post("/employee/add", queryData).then(() => {
      setModalActive(false);
      fetchUsers();
      setUserFirstName("");
      setUserLastName("");
    });
  };

  const deleteUserHandler = async () => {
    try {
      await axios.post("/employee/del", {
        employeeId: userToBeDeleted,
      });

      setModalDeleteActive(false);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUsersHandler = () => {
    isCheck.forEach(async (employeeId) => {
      try {
        await axios.post("/employee/del", {
          employeeId: employeeId,
        });

        fetchUsers();
      } catch (err) {
        console.log(err);
      }
    });

    setIsCheck([]);

    setDeleteUsersModalActive(false);
  };

  const showDeleteModal = (employeeId) => {
    setUserToBeDeleted(employeeId);

    setModalDeleteActive(true);
  };

  const showEditUserModal = (employeeId) => {
    setUserToBeUpdated(users.find((user) => user.employee_id === employeeId));

    setUserFirstName(
      users.find((user) => user.employee_id === employeeId).first_name
    );
    setUserLastName(
      users.find((user) => user.employee_id === employeeId).last_name
    );

    setEditUserModalActive(true);
  };

  const submitEditHandler = () => {
    const queryData = {
      employeeId: userToBeUpdated.employee_id,
      firstName: userFirstName,
      lastName: userLastName,
    };

    axios.post("/employee/edit", queryData).then(() => {
      setEditUserModalActive(false);
      fetchUsers();
      setUserFirstName("");
      setUserLastName("");
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <div className="users__list">
      <h2 className="title">Справочник сотрудников</h2>
      <div className="forms">
        <button
          className="new-employee-button"
          onClick={() => setModalActive(true)}
        >
          Добавить сотрудника
        </button>
        <input
          className="search"
          placeholder="Найти сотрудника"
          onChange={(e) => filterUsers(e.target.value)}
        ></input>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="table__title table__title_check">
              <Checkbox
                type="checkbox"
                name="selectAll"
                id="selectAll"
                handleClick={handleSelectAll}
                isChecked={isCheckAll}
                className="check__box check__box_all"
              />
            </th>
            <th className="table__title" onClick={() => sortUsers("last_name")}>
              Сотрудник
            </th>
            <th className="table__title"></th>
          </tr>
          {filteredUsers?.map((user) => (
            <tr key={user.employee_id}>
              <td className="table__cell">
                <Checkbox
                  key={user.employee_id}
                  type="checkbox"
                  name={user.first_name}
                  id={user.employee_id}
                  handleClick={handleClick}
                  isChecked={isCheck.includes(user.employee_id)}
                  className="check__box"
                />
              </td>
              <td className="table__cell">
                <div className="user">
                  <img
                    className="user__photo"
                    src={require("../assets/user.png")}
                    alt={`${user.last_name} ${user.first_name}`}
                  ></img>
                  <span className="user__name">{`${user.last_name} ${user.first_name}`}</span>
                </div>
              </td>
              <td className="table__cell">
                <div className="user__buttons">
                  <button>
                    <img
                      className="edit"
                      src={require("../assets/edit.png")}
                      alt="Редактировать"
                      onClick={() => showEditUserModal(user.employee_id)}
                    ></img>
                  </button>
                  <button>
                    <img
                      className="remove"
                      src={require("../assets/remove.png")}
                      alt="Удалить"
                      onClick={() => showDeleteModal(user.employee_id)}
                    ></img>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </thead>
      </table>
      <footer className={`footer ${isCheck.length > 0 ? "footer_active" : ""}`}>
        <div className="footer__buttons">
          <button
            className="button button_additional"
            onClick={() => setDeleteUsersModalActive(true)}
          >
            Удалить
          </button>
        </div>
      </footer>
      <Modal active={modalActive} setActive={setModalActive}>
        <form className="new-employee" onSubmit={(e) => e.preventDefault()}>
          <div className="new-employee__inputs">
            <label className="new-employee__label">
              <span>Имя</span>
              <input
                className="new-employee__input"
                type="text"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
              ></input>
            </label>
            <label className="new-employee__label">
              <span>Фамилия</span>
              <input
                className="new-employee__input"
                type="text"
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
              ></input>
            </label>
          </div>
          <button className="new-employee-button" onClick={submitHandler}>
            Добавить сотрудника
          </button>
        </form>
      </Modal>
      <Modal active={editUserModalActive} setActive={setEditUserModalActive}>
        <form className="new-employee" onSubmit={(e) => e.preventDefault()}>
          <div className="new-employee__inputs">
            <label className="new-employee__label">
              <span>Имя</span>
              <input
                className="new-employee__input"
                type="text"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
              ></input>
            </label>
            <label className="new-employee__label">
              <span>Фамилия</span>
              <input
                className="new-employee__input"
                type="text"
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
              ></input>
            </label>
          </div>
          <button className="new-employee-button" onClick={submitEditHandler}>
            Сохранить
          </button>
        </form>
      </Modal>
      <Modal active={modalDeleteActive} setActive={setModalDeleteActive}>
        <div className="confirm">
          <p className="confirm__text">Вы уверены?</p>
          <div className="confirm__buttons">
            <button
              className="confirm__button confirm__button_accept"
              onClick={deleteUserHandler}
            >
              Да
            </button>
            <button
              className="confirm__button confirm__button_decline"
              onClick={() => setModalDeleteActive(false)}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        active={deleteUsersModalActive}
        setActive={setDeleteUsersModalActive}
      >
        <div className="confirm">
          <p className="confirm__text">Вы уверены?</p>
          <div className="confirm__buttons">
            <button
              className="confirm__button confirm__button_accept"
              onClick={deleteUsersHandler}
            >
              Да
            </button>
            <button
              className="confirm__button confirm__button_decline"
              onClick={() => setDeleteUsersModalActive(false)}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersPage;
