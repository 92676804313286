import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  users: [],
  status: null,
  isLoading: false,
};

export const addUser = createAsyncThunk("user/addUser", async (params) => {
  try {
    const { data } = await axios.post(
      "http://lk.academ.vinpin.ru/api/employee/add.php",
      params
    );

    console.log(data);

    return data;
  } catch (error) {
    console.log(error);
  }
});

// export const getUsers = createAsyncThunk("user/getUsers", async () => {
//   try {
//     const { data } = await axios.post(
//       "http://lk.academ.vinpin.ru/api/courses/list.php"
//     );

//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// });

export const userSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = payload.result;
    });
    builder.addCase(addUser.rejected, (state) => {
      state.isLoading = false;
    });
    // builder.addCase(getCourses.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getCourses.fulfilled, (state, action) => {
    //   state.courses = action.payload.result;
    //   state.isLoading = false;
    // });
    // builder.addCase(getCourses.rejected, (state) => {
    //   state.isLoading = false;
    // });
  },
});

export default userSlice.reducer;
