import "./Checkbox.css";

const Checkbox = ({ id, type, name, handleClick, isChecked, className }) => {
  return (
    <label className="check">
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked}
        className="check__input"
      />
      <span className={className}></span>
    </label>
  );
};

export default Checkbox;
