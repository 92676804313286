import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  isLoading: false,
  status: null,
  token: window.localStorage.getItem("token"),
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }) => {
    try {
      const { data } = await axios.post("/auth/login", { username, password });

      if (data.token) {
        window.localStorage.setItem("token", data.token);
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getMe = createAsyncThunk("auth/getMe", async () => {
  try {
    const { data } = await axios.get("/auth/me");

    return data;
  } catch (err) {
    window.localStorage.removeItem("token");
    console.log(err);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoading = false;
      state.status = "Вы вышли из системы";
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.status = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = payload.message;
      state.token = payload.token;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = payload.message;
    });

    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
      state.status = null;
    });
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = null;
      state.token = payload.token;
    });
    builder.addCase(getMe.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = payload.message;
    });
  },
});

export const checkIsAuth = (state) => Boolean(state.auth.token);
export const { logout } = authSlice.actions;

export default authSlice.reducer;
