import { useCallback, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../utils/axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import Loader from "../components/Loader";
import "./UserPage.css";
import { copyToClipboard } from "../utils/copy";
import { formatSeconds } from "../utils/formatSeconds";
import Modal from "../components/Modal/Modal";
import Checkbox from "../components/Checkbox/Checkbox";

import { Tooltip } from "react-tooltip";

dayjs.extend(timezone);
dayjs.extend(utc);

const UserPage = () => {
  const params = useParams();
  const [courseId, setCourseId] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [userStats, setUserStats] = useState(null);
  const [bestAttempt, setBestAttempt] = useState(null);
  const [averagePageTime, setAveragePageTime] = useState(null);
  const [percentageOfPagesViewed, setPercentageOfPagesViewed] = useState(null);
  const [averageVisitedPages, setAverageVisitedPages] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [errorsModalActive, setErrorsModalActive] = useState(false);
  const [errors, setErrors] = useState([]);
  const [cAttempts, setCAttempts] = useState([]);
  const [currentAttempt, setCurrentAttempt] = useState(false);
  const [currentAttemptArr, setCurrentAttemptArr] = useState(false);

  const fetchUserStats = useCallback(
    async (courseId) => {
      const { data } = await axios.post(`/courses/${courseId}/users/get_stat`, {
        id: courseId,
        employeeId: params.userId,
      });

      console.log(data);

      const attempts = data.result?.stat;

      if (courseId == 1) {
        setAveragePageTime(
          attempts.reduce(
            (acc, curr) => acc + curr.stat.average_page_time.time,
            0
          ) / attempts.length
        );
        setPercentageOfPagesViewed(
          attempts.reduce(
            (acc, curr) => acc + curr.stat.percentage_of_pages_viewed,
            0
          ) / attempts.length
        );
        setAverageVisitedPages(
          attempts.reduce((acc, curr) => acc + curr.visited_pages, 0) /
            attempts.length
        );
        if (attempts.length > 0) {
          setTotalPages(attempts[0].total_pages);
        }

        const cAttempts = attempts.map((item, i) => {
          return {
            number: i + 1,
            rating: item.rating,
            averagePageTime: item.stat.average_page_time.time,
            research: item.stat.characteristic.research.total,
            visitedPages: item.visited_pages,
            percentageOfPagesViewed: item.stat.percentage_of_pages_viewed,
          };
        });

        setCAttempts(cAttempts);

        for (const i in data.result.stat) {
          data.result.stat[i].errorsCount = data.result.stat[
            i
          ].stat.test.questions.reduce(
            (acc, curr) => acc + (curr.answer_correct === "false" ? 1 : 0),
            0
          );
        }
      }

      setUserStats(data.result);

      if (attempts.length > 0) {
        console.log(attempts);
        if (attempts.length === 1) {
          setBestAttempt(attempts[0]);
        } else {
          let bestAttemptIndex = null;

          bestAttemptIndex = attempts?.reduce((acc, curr, i) => {
            return Number(attempts[acc].rating) > Number(curr.rating) ? acc : i;
          }, 0);

          if (courseId === 3 || courseId === 4 || courseId === 2) {
            bestAttemptIndex = attempts?.reduce((acc, curr, i) => {
              return Number(attempts[acc].miss) < Number(curr.miss) ? acc : i;
            }, 0);
          }

          if (bestAttemptIndex !== null) {
            setBestAttempt(attempts[bestAttemptIndex]);
          }
        }
      }

      setIsLoading(false);
    },
    [params]
  );

  const sortUsers = (field1, field2 = false) => {
    console.log(userStats);
    userStats.stat.sort((a, b) => {
      let first = a[field1];
      let second = b[field1];

      if (field1 === "scores" || field1 === "miss" || field1 === "time") {
        first = Number(a[field1]);
        second = Number(b[field1]);
      }

      if (field2) {
        first = a[field1][field2];
        second = b[field1][field2];
      }

      if ((field2 && field1 == "stat") || field1 === "date") {
        return sortDirection
          ? dayjs(first).isAfter(dayjs(second))
            ? 1
            : -1
          : dayjs(first).isAfter(dayjs(second))
          ? -1
          : 1;
      }

      return sortDirection
        ? first > second
          ? 1
          : -1
        : first > second
        ? -1
        : 1;
    });

    setSortDirection(!sortDirection);
  };

  const handleClick = (event) => {
    const { id, checked } = event.target;
    const attempt = cAttempts.find((item) => item.number == id);

    setCurrentAttempt(id);
    setCurrentAttemptArr(attempt);
    setPercentageOfPagesViewed(attempt.percentageOfPagesViewed);
    setAverageVisitedPages(attempt.visitedPages);

    if (!checked) {
      setPercentageOfPagesViewed(
        cAttempts.reduce((acc, curr) => acc + curr.percentageOfPagesViewed, 0) /
          cAttempts.length
      );
      setAverageVisitedPages(
        cAttempts.reduce((acc, curr) => acc + curr.visitedPages, 0) /
          cAttempts.length
      );
      setCurrentAttempt(false);
      setCurrentAttemptArr([]);
    }
  };

  const changeCourseType = (courseId) => {
    setCourseId(courseId);
    fetchUserStats(courseId);
  };

  const showErrors = (i, attempts) => {
    const err = attempts.stat[i].stat.test.questions.map((item, i) => {
      return {
        question: i + 1 + ". " + item.ask.ru,
        answer: item.user_answer
          ? [
              Number(item.user_answer) +
                1 +
                ". " +
                item.answers.ru[item.user_answer],
            ]
          : ["Нет ответа"],
        isCorrect: item.answer_correct === "true" && item.user_answer,
        correctAnswer:
          item.answer_correct === "false"
            ? item.answers.ru[item.correct]
            : null,
      };
    });

    setErrors(err);
    setErrorsModalActive(true);
  };

  const showExamErrors = (i, attempts) => {
    const err = attempts.stat[i].test.map((item, i) => {
      return {
        question: i + 1 + ". " + item.question,
        answer: item.answer,
        isCorrect: item.isCorrect,
      };
    });

    console.log(err);

    setErrors(err);
    setErrorsModalActive(true);
  };

  const getResultTooltip = (errorsAmount) => {
    if (errorsAmount >= 0 && errorsAmount <= 3) {
      return `<span>Студент сделал наименьшее кол-во ошибок, точность подбора запчастей высокая.</span>
              <br>
              <span><b><i>Рекомендация студенту:</b></i> Так держать!</span>`;
    } else if (errorsAmount >= 4 && errorsAmount <= 5) {
      return `<span>Студент сделал небольшое кол-во ошибок в подборе.</span>
              <br>
              <span><b><i>Рекомендация студенту:</b></i> Для повышения результата тщательнее ознакомиться с заданием перед его выполнением. По необходимости прибегать к подсказкам внутри тренажера.</span>`;
    } else if (errorsAmount >= 6 && errorsAmount <= 9) {
      return `<span>Студентом допущено много ошибок в подборе запчастей.</span>
              <br>
              <span><b><i>Рекомендация студенту:</b></i> Требуется повтор материалов интерактивных инструкций и/или изучить отдельные каталоги, с которыми возникают трудности.</span>`;
    } else if (errorsAmount >= 10) {
      return `<span>Студент сделал недопустимое кол-во ошибок в подборе запчастей, точность подбора низкая.</span>
              <br>
              <span><b><i>Рекомендация студенту:</b></i> Необходимо внимательно ознакомиться с интерактивными инструкциями и заданиями. Такое кол-во ошибок может также сказаться на скорости подбора запчастей.</span>`;
    }
  };

  const getPracticeResultPoints = (errorsAmount) =>
    Math.max(0, Math.round(100 - 100 * (errorsAmount / 20)));

  const getExamResultPoints = (errorsAmount) =>
    Math.max(0, Math.round(100 - 100 * (errorsAmount / 30)));

  useEffect(() => {
    if (params.courseId == 5) {
      fetchUserStats(1);
    } else if (params.courseId == 7) {
      fetchUserStats(3);
      setCourseId(3);
    } else if (params.courseId == 9) {
      fetchUserStats(2);
      setCourseId(2);
    } else if (params.courseId == 11) {
      fetchUserStats(2);
      setCourseId(2);
    } else {
      fetchUserStats(params.courseId);
      setCourseId(Number(params.courseId));
    }
  }, [params.courseId, fetchUserStats]);

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <div className="users__list">
      <header className="course__header">
        <div className="title__wrapper">
          <h2 className="title">{`${userStats?.userInfo.last_name} ${userStats?.userInfo.first_name}`}</h2>
          {params.courseId == 5 && (
            <div className="buttons__wrapper">
              <button
                className={`course-selector${
                  courseId == 1 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(1)}
              >
                Тестирование
              </button>
              <button
                className={`course-selector${
                  courseId == 3 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(3)}
              >
                Тренажер
              </button>
            </div>
          )}
          {params.courseId == 7 && (
            <div className="buttons__wrapper">
              <button
                className={`course-selector${
                  courseId == 3 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(3)}
              >
                Тренажёр
              </button>
              <button
                className={`course-selector${
                  courseId == 4 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(4)}
              >
                Экзамен
              </button>
            </div>
          )}
          {params.courseId == 11 && (
            <div className="buttons__wrapper">
              <button
                className={`course-selector${
                  courseId == 2 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(2)}
              >
                Тестирование
              </button>
              <button
                className={`course-selector${
                  courseId == 3 ? " course-selector_active" : ""
                }`}
                onClick={() => changeCourseType(3)}
              >
                Тренажёр
              </button>
            </div>
          )}
        </div>
        <Link to={`/courses/${params.courseId}`} className="back">
          Назад к курсу
        </Link>
      </header>
      {Object.values(userStats?.stat).length > 0 && (
        <div className="program">
          <div className="program__total">
            <table className="progress">
              <tbody>
                <tr>
                  <td className="programm__cell">
                    <span className="subtitle">Программа:</span>{" "}
                  </td>
                  <td className="programm__cell">
                    {courseId == 1 ? (
                      <span className="course-title">
                        Теоретический курс (базовый)
                      </span>
                    ) : courseId == 4 ? (
                      <span className="course-title">Экзамен</span>
                    ) : courseId == 2 ? (
                      <span className="course-title">
                        Теоретический курс (продвинутый)
                      </span>
                    ) : (
                      <span className="course-title">
                        Практический курс (базовый)
                      </span>
                    )}
                  </td>
                </tr>
                {courseId == 1 && (
                  <tr>
                    <td className="programm__cell">
                      <span className="subtitle">Прогресс:</span>
                    </td>
                    <td className="programm__cell">
                      <div className="progress">
                        <div className="progress__line">
                          <div className="progress__bar">
                            <div
                              className="progress__fill"
                              style={{ width: `${percentageOfPagesViewed}%` }}
                            ></div>
                          </div>
                          <div className="progress__description">
                            <span className="description">
                              {averageVisitedPages} страниц
                            </span>
                            <span className="description">из {totalPages}</span>
                          </div>
                        </div>
                        <span className="progress__result">
                          {percentageOfPagesViewed}%
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {!currentAttempt ? (
              <div className="course__summary">
                <div className="course__info">
                  <div className="summary__finished">
                    <h3 className="summary__title">Попыток</h3>
                    <div className="summary__info">
                      <span className="finished__result">
                        {userStats.stat.length}
                      </span>
                    </div>
                  </div>
                  <div className="summary__finished">
                    <h3 className="summary__title">Лучший результат</h3>
                    <div className="summary__info">
                      {courseId == 1 ? (
                        <span className="finished__result finished__result_best">
                          {bestAttempt?.rating}
                        </span>
                      ) : courseId === 4 ? (
                        <span className="finished__result finished__result_best">
                          {getExamResultPoints(bestAttempt?.miss)}
                        </span>
                      ) : courseId === 2 ? (
                        <span className="finished__result finished__result_best">
                          {getExamResultPoints(bestAttempt?.miss)}
                        </span>
                      ) : (
                        <span className="finished__result finished__result_best">
                          {getPracticeResultPoints(bestAttempt?.miss)}
                        </span>
                      )}
                      <span className="finished__total">из</span>
                      <span className="finished__total">
                        {courseId == 1 ? 100 : 100}
                      </span>
                    </div>
                  </div>
                  {courseId == 1 && (
                    <div className="summary__finished">
                      <h3 className="summary__title">
                        Ср. время пролистывания
                      </h3>
                      <div className="summary__info">
                        <span className="finished__result">
                          {averagePageTime} сек.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="course__summary">
                <div className="course__info">
                  <div className="summary__finished">
                    <h3 className="summary__title">№ Попытки</h3>
                    <div className="summary__info">
                      <span className="finished__result">
                        {currentAttemptArr.number}
                      </span>
                    </div>
                  </div>
                  <div className="summary__finished">
                    <h3 className="summary__title">Результат</h3>
                    <div className="summary__info">
                      <span className="finished__result finished__result_best">
                        {currentAttemptArr.rating}
                      </span>
                      <span className="finished__total">из</span>
                      <span className="finished__total">100</span>
                    </div>
                  </div>
                  <div className="summary__finished">
                    <h3 className="summary__title">Ср. время пролистывания</h3>
                    <div className="summary__info">
                      <span className="finished__result">
                        {currentAttemptArr.averagePageTime} сек.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {currentAttempt && (
            <div className="program__user">
              <h3 className="program__title">
                <span className="subtitle">Исследование:</span>{" "}
              </h3>
              <div className="research">
                {bestAttempt?.stat.characteristic.research.total}
              </div>
            </div>
          )}
        </div>
      )}
      {userStats?.stat.length > 0 ? (
        <div className="table__wrapper">
          {courseId == 1 ? (
            <table className="table">
              <thead>
                <tr>
                  <th className="table__title"></th>
                  <th className="table__title">№ Попытки</th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("rating")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Результат</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("stat", "time_start")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время начала</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("stat", "time_start")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время прохождения</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("errorsCount")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Кол-во ошибок</span>
                  </th>
                  <th className="table__title">Ключ доступа</th>
                </tr>
              </thead>
              <tbody>
                {userStats.stat.map((attempt, i) => (
                  <tr key={attempt.date}>
                    <td className="table__cell table__cell_centered">
                      <Checkbox
                        key={i + 1}
                        type="checkbox"
                        name={i + 1}
                        id={i + 1}
                        handleClick={handleClick}
                        isChecked={currentAttempt == i + 1}
                        className="check__box"
                      />
                    </td>
                    <td className="table__cell table__cell_centered">
                      {i + 1}
                    </td>
                    <td className="table__cell">
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              attempt.rating >= 85
                                ? "result__count_success"
                                : attempt.rating >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {attempt.rating}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">
                        {dayjs(attempt.stat?.time_start).format(
                          "DD.MM.YYYY HH:mm:ss"
                        )}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">
                        {dayjs(
                          dayjs(attempt.stat?.time_end).diff(
                            attempt.stat?.time_start
                          )
                        )
                          .tz("Etc/UTC")
                          .format("HH:mm:ss")}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div
                        className="errors-count"
                        onClick={() => showErrors(i, userStats)}
                      >
                        {attempt.errorsCount}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="access-key">
                        <img
                          className="access-key__copy"
                          src={require("../assets/copy.png")}
                          alt="Скопировать"
                          onClick={() => copyToClipboard(attempt?.key)}
                        ></img>
                        {attempt?.key ? (
                          <span className="access-key__key">
                            {attempt?.key}
                          </span>
                        ) : (
                          <span className="access-key__plug">∗∗∗∗∗</span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : courseId === 4 || courseId === 2 ? (
            <table className="table">
              <thead>
                <tr>
                  <th className="table__title">№ Попытки</th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("miss")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Результат</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("scores")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Баллы</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("date")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время начала</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("time")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время прохождения</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("miss")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Кол-во ошибок (в баллах)</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userStats.stat.map((attempt, i) => (
                  <tr key={attempt.date}>
                    <td className="table__cell table__cell_centered">
                      {i + 1}
                    </td>
                    <td className="table__cell">
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              getExamResultPoints(attempt.miss) >= 85
                                ? "result__count_success"
                                : getExamResultPoints(attempt.miss) >= 75
                                ? "result__count_warning"
                                : getExamResultPoints(attempt.miss) >= 50
                                ? "result__count_danger"
                                : "result__count_failed"
                            }`}
                          >
                            {getExamResultPoints(attempt.miss)}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">{attempt.scores}</div>
                    </td>
                    <td className="table__cell">
                      <div className="date">
                        {dayjs(attempt.date).format("DD.MM.YYYY HH:mm:ss")}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">{formatSeconds(attempt.time)}</div>
                    </td>
                    <td className="table__cell">
                      <div
                        className={`errors-count ${
                          attempt.miss <= 3
                            ? "result__count_success"
                            : attempt.miss <= 5
                            ? "result__count_warning"
                            : attempt.miss <= 9
                            ? "result__count_danger"
                            : "result__count_failed"
                        }`}
                        onClick={() => showExamErrors(i, userStats)}
                      >
                        {attempt.miss}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th className="table__title">№ Попытки</th>
                  <th className="table__title">Каталог</th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("miss")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Результат</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("scores")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Баллы</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("date")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время начала</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("time")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Время прохождения</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("miss")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Кол-во ошибок (в баллах)</span>
                  </th>
                  <th
                    className="table__title"
                    onClick={() => sortUsers("help_use")}
                  >
                    <img
                      className="table__sort"
                      src={require("../assets/sort.png")}
                      alt="Сортировать"
                    ></img>
                    <span>Кол-во подсказок</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userStats.stat.map((attempt, i) => (
                  <tr key={attempt.date}>
                    <td className="table__cell table__cell_centered">
                      {i + 1}
                    </td>
                    <td className="table__cell">
                      <div>{attempt.name}</div>
                    </td>
                    <td className="table__cell">
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              getPracticeResultPoints(attempt.miss) >= 85
                                ? "result__count_success"
                                : getPracticeResultPoints(attempt.miss) >= 75
                                ? "result__count_warning"
                                : getPracticeResultPoints(attempt.miss) >= 50
                                ? "result__count_danger"
                                : "result__count_failed"
                            }`}
                          >
                            {getPracticeResultPoints(attempt.miss)}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">{attempt.scores}</div>
                    </td>
                    <td className="table__cell">
                      <div className="date">
                        {dayjs(attempt.date).format("DD.MM.YYYY HH:mm:ss")}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">{formatSeconds(attempt.time)}</div>
                    </td>
                    <td className="table__cell">
                      <div
                        className={`errors-count ${
                          attempt.miss <= 3
                            ? "result__count_success"
                            : attempt.miss <= 5
                            ? "result__count_warning"
                            : attempt.miss <= 9
                            ? "result__count_danger"
                            : "result__count_failed"
                        }`}
                        data-tooltip-id="result-tooltip"
                        data-tooltip-html={getResultTooltip(attempt.miss)}
                      >
                        {attempt.miss}
                      </div>
                    </td>
                    <td className="table__cell">
                      <div className="date">{attempt.help_use}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <>Сотрудник ещё не проходил тесты</>
      )}
      <Modal active={errorsModalActive} setActive={setErrorsModalActive}>
        <ul>
          {errors?.map((error) => {
            return (
              <li className="answer-item">
                <div className="question">{error.question}</div>
                <div
                  className={`answer ${
                    error.isCorrect ? "answer_correct" : "answer_incorrect"
                  }`}
                >
                  {error.answer.map((answer) => (
                    <span>{answer}</span>
                  ))}
                </div>
                {error.correctAnswer !== null && (
                  <span className="answer correct-answer">
                    Верный ответ: {error.correctAnswer}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </Modal>
      <Tooltip
        style={{
          maxWidth: "400px",
          backgroundColor: "#fff",
          color: "#000",
        }}
        id="result-tooltip"
        border="1px solid #000"
      />
    </div>
  );
};

export default UserPage;
