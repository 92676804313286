import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, checkIsAuth } from "../redux/slices/auth";
import "./LoginPage.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isAuth = useSelector(checkIsAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);

  const handleSubmit = () => {
    try {
      dispatch(login({ username, password }));

      setUsername("");
      setPassword("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login">
      <aside className="login__aside">
        <img
          className="login__logo"
          src={require("../assets/login-logo.png")}
          alt="VINPIN Академия"
        ></img>
      </aside>
      <main className="login__main">
        <img
          className="login__logo_mobile"
          src={require("../assets/logo.png")}
          alt="VINPIN Академия"
        ></img>
        <div className="login__wrapper">
          <h2 className="login__title">Добро пожаловать!</h2>
          <h3 className="login__subtitle">Введи свои данные для входа</h3>
          <form className="login__form" onSubmit={(e) => e.preventDefault()}>
            <input
              className="login__input"
              type="text"
              placeholder="Логин"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            ></input>
            <input
              className="login__input"
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <button
              className="login__submit"
              type="submit"
              onClick={handleSubmit}
            >
              Войти
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
