import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { getMe, checkIsAuth } from "./redux/slices/auth";
import { getCourses } from "./redux/slices/course";

import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import CoursesPage from "./pages/CoursesPage";
import UsersPage from "./pages/UsersPage";
import CoursePage from "./pages/CoursePage";
import UserPage from "./pages/UserPage";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useSelector(checkIsAuth);

  useEffect(() => {
    dispatch(getMe());

    if (!isAuth && location.pathname !== "/login") {
      navigate("/login");
    }

    if (location.pathname === "/") {
      dispatch(getCourses());
      navigate("/courses");
    }
  }, [dispatch, navigate, isAuth, location]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route
          path="/courses"
          element={
            <Layout>
              <CoursesPage />
            </Layout>
          }
        ></Route>
        <Route
          path="/users"
          element={
            <Layout>
              <UsersPage />
            </Layout>
          }
        ></Route>
        <Route
          path="/courses/:id"
          element={
            <Layout>
              <CoursePage />
            </Layout>
          }
        ></Route>
        <Route
          path="/courses/:courseId/user/:userId"
          element={
            <Layout>
              <UserPage />
            </Layout>
          }
        ></Route>
      </Routes>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default App;
