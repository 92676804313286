import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  courses: [],
  isLoading: false,
};

export const getCourses = createAsyncThunk("course/getCourses", async () => {
  try {
    const { data } = await axios.get("/courses/list");

    return data;
  } catch (error) {
    console.log(error);
  }
});

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCourses.fulfilled, (state, { payload }) => {
      state.courses = payload;
      state.isLoading = false;
    });
    builder.addCase(getCourses.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default courseSlice.reducer;
