import { useState, useCallback, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../utils/axios";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import "./CoursePage.css";
import Modal from "../components/Modal/Modal";
import Checkbox from "../components/Checkbox/Checkbox";
import { copyToClipboard } from "../utils/copy";

const CoursePage = () => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [filteredAllUsers, setFilteredAllUsers] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [addUserModalActive, setAddUserModalActive] = useState(false);
  const [deleteUserModalActive, setDeleteUserModalActive] = useState(false);
  const [usersToBeAdded] = useState([]);
  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const [bestValue, setBestValue] = useState(0);
  const [averageValue, setAverageValue] = useState(0);
  const [worstValue, setWorstValue] = useState(0);
  const [average, setAverage] = useState([]);
  const [done, setDone] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [deleteUsersModalActive, setDeleteUsersModalActive] = useState(false);
  const [payUsersModalActive, setPayUsersModalActive] = useState(false);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(filteredUsers.map((li) => li.employee_id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const fetchCourse = useCallback(async () => {
    const { data } = await axios.get(`/courses/${params.id}/users/list`);

    console.log(data);

    setCourse(data.course);
    setFilteredUsers(data.employees);

    setIsLoading(false);
  }, [params.id]);

  const countAverage = () => {
    if (average.length > 0) {
      const sum = +average.reduce((acc, curr) => acc + +curr, 0);

      setAverageValue(Math.round(sum / average.length));
    }
  };

  const filterUsers = (mask) => {
    const temp = users.filter((user) => {
      const username = `${user.last_name} ${user.first_name}`;

      return username.toLowerCase().includes(mask.toLowerCase());
    });

    setFilteredUsers(temp);
  };

  const fetchAllUsers = useCallback(async () => {
    if (!allUsers) {
      const { data } = await axios.get("/employee/list");

      const tempUsers = data.filter(
        (allUser) =>
          !users.map((user) => user.employee_id).includes(allUser.employee_id)
      );

      setAllUsers(tempUsers);
      setFilteredAllUsers(tempUsers);
      setIsLoading(false);
    }
  }, [users, allUsers]);

  const filterAllUsers = (mask) => {
    console.log(mask);
    const temp = allUsers.filter((user) => {
      const username = `${user.last_name} ${user.first_name}`;

      return username.toLowerCase().includes(mask.toLowerCase());
    });

    setFilteredAllUsers(temp);
  };

  const sortUsers = (field1, field2 = false) => {
    filteredUsers.sort((a, b) => {
      let first = a[field1];
      let second = b[field1];

      if (field2) {
        first = a[field1][field2];
        second = b[field1][field2];
      }

      if (field1 == "lastAttempt") {
        return sortDirection
          ? dayjs(first).isAfter(dayjs(second))
            ? 1
            : -1
          : dayjs(first).isAfter(dayjs(second))
          ? -1
          : 1;
      }

      return sortDirection
        ? first > second
          ? 1
          : -1
        : first > second
        ? -1
        : 1;
    });

    setSortDirection(!sortDirection);
  };

  const showAddUser = () => {
    fetchAllUsers();
    setAddUserModalActive(true);
  };

  const addUser = (userId) => {
    if (!usersToBeAdded.includes(userId)) {
      usersToBeAdded.push(userId);
    } else {
      const index = usersToBeAdded.indexOf(userId);

      if (index !== -1) {
        usersToBeAdded.splice(index, 1);
      }
    }
  };

  const submitAddUser = async () => {
    await axios.post(`/courses/${params.id}/users/add`, {
      employeeIds: usersToBeAdded.join(","),
    });

    setAddUserModalActive(false);
    fetchCourse();
  };

  const deleteUserHandler = async () => {
    try {
      await axios.post(`/courses/${params.id}/users/del`, {
        employeeIds: userToBeDeleted,
      });

      setDeleteUserModalActive(false);
      fetchCourse();
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteModal = (employeeId) => {
    setUserToBeDeleted(employeeId);

    setDeleteUserModalActive(true);
  };

  const deleteUsersHandler = async () => {
    try {
      const { data } = await axios.post(`/courses/${params.id}/users/del`, {
        employeeIds: isCheck.join(","),
      });

      console.log(data);

      fetchCourse();
    } catch (err) {
      console.log(err);
    }

    setIsCheck([]);

    setDeleteUsersModalActive(false);
  };

  const payUsersHandler = () => {
    axios
      .post(`/courses/${params.id}/users/pay`, {
        employeeIds: isCheck.join(","),
      })
      .then((response) => {
        console.log(response.data);

        fetchCourse();

        setIsCheck([]);

        setPayUsersModalActive(false);
      });
  };

  useEffect(() => {
    fetchCourse();
  }, [fetchCourse]);

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <section className="course">
      <div className="course__header">
        <h2 className="course__title">
          {course?.title} {course?.type && <span>({course?.type})</span>}
        </h2>
        <Link to={`/courses/`} className="back">
          Все курсы
        </Link>
      </div>

      <div className="course__summary">
        {(course.id === 1 || course.id === 5) && (
          <div className="course__info">
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Прошли обучение </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тестирование)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">{course?.theory?.done}</span>
                <span className="finished__total">из</span>
                <span className="finished__total">{course?.theory?.total}</span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Средний результат </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тестирование)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">
                  {course?.theory?.average}
                </span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Лучший результат </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тестирование)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">{course?.theory?.best}</span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
                <div className="summary__username">
                  <span className="summary__first-name">
                    {course?.theory.firstName}
                  </span>
                  <span className="summary__last-name">
                    {course?.theory.lastName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {(course.id === 3 ||
          course.id === 5 ||
          course.id === 7 ||
          course.id === 11) && (
          <div className="course__info">
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Прошли обучение </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тренажер)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">
                  {course?.practice?.done}
                </span>
                <span className="finished__total">из</span>
                <span className="finished__total">
                  {course?.practice?.total}
                </span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Средний результат </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тренажер)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">
                  {course?.practice?.average}
                </span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Лучший результат </span>
                {course.ids.length > 1 && (
                  <span className="subtext">(Тренажер)</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">
                  {course?.practice?.best}
                </span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
                <div className="summary__username">
                  <span className="summary__first-name">
                    {course?.practice.firstName}
                  </span>
                  <span className="summary__last-name">
                    {course?.practice.lastName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {(course.id === 7 || course.id === 9 || course.id === 11) && (
          <div className="course__info">
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Прошли обучение </span>
                {course.ids.length > 1 && course.id === 7 && (
                  <span className="subtext">(Экзамен)</span>
                )}
                {course.ids.length > 1 && course.id === 11 && (
                  <span className="subtext">(Теория (продв.))</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">{course?.exam?.done}</span>
                <span className="finished__total">из</span>
                <span className="finished__total">{course?.exam?.total}</span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Средний результат </span>
                {course.ids.length > 1 && course.id === 7 && (
                  <span className="subtext">(Экзамен)</span>
                )}
                {course.ids.length > 1 && course.id === 11 && (
                  <span className="subtext">(Теория (продв.))</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">
                  {course?.exam?.average}
                </span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
              </div>
            </div>
            <div
              className={`${
                course.ids.length > 1
                  ? "summary__finished_full"
                  : "summary__finished"
              }`}
            >
              <h3 className="summary__title">
                <span>Лучший результат </span>
                {course.ids.length > 1 && course.id === 7 && (
                  <span className="subtext">(Экзамен)</span>
                )}
                {course.ids.length > 1 && course.id === 11 && (
                  <span className="subtext">(Теория (продв.))</span>
                )}
              </h3>
              <div className="summary__info">
                <span className="finished__result">{course?.exam?.best}</span>
                <span className="finished__total">из</span>
                <span className="finished__total">100</span>
                <div className="summary__username">
                  <span className="summary__first-name">
                    {course?.exam.firstName}
                  </span>
                  <span className="summary__last-name">
                    {course?.exam.lastName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="forms">
        <input
          className="search"
          placeholder="Найти сотрудника"
          onChange={(e) => filterUsers(e.target.value)}
        ></input>
        <button className="new-employee-button" onClick={showAddUser}>
          + Добавить сотрудника
        </button>
      </div>
      <div className="table__wrapper">
        <table className="table">
          <thead>
            <tr>
              <th className="table__title">
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  className="check__box check__box_all"
                />
              </th>
              <th
                className="table__title table__title_left"
                onClick={() => sortUsers("last_name")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Сотрудник</span>
              </th>
              <th
                className="table__title"
                onClick={() => sortUsers("subscribe", "pay")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Статус</span>
              </th>
              <th className="table__title">
                <span>Ключ доступа</span>
              </th>
              <th
                className="table__title"
                onClick={() => sortUsers("rating", "best")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Лучший результат</span>
              </th>
              <th
                className="table__title"
                onClick={() => sortUsers("rating", "last")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Последний результат</span>
              </th>
              <th
                className="table__title"
                onClick={() => sortUsers("lastAttempt")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Дата прохождения</span>
              </th>
              <th
                className="table__title"
                onClick={() => sortUsers("rating", "counter")}
              >
                <img
                  className="table__sort"
                  src={require("../assets/sort.png")}
                  alt="Сортировать"
                ></img>
                <span>Всего попыток</span>
              </th>
              <th className="table__title"></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers?.map((user) => (
              <tr key={user.employee_id}>
                <td className="table__cell">
                  <Checkbox
                    key={user.employee_id}
                    type="checkbox"
                    name={user.first_name}
                    id={user.employee_id}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(user.employee_id)}
                    className="check__box"
                  />
                </td>
                <td className="table__cell">
                  <Link
                    className="user"
                    to={`/courses/${course?.id}/user/${user.employee_id}`}
                  >
                    <img
                      className="user__photo"
                      src={require("../assets/user.png")}
                      alt={`${user.last_name} ${user.first_name}`}
                    ></img>
                    <span className="user__name">{`${user.last_name} ${user.first_name}`}</span>
                  </Link>
                </td>
                <td className="table__cell table__cell_centered">
                  <div className="cell__wrapper">
                    {user.theory?.subscribe && (
                      <div className="status__wrapper">
                        <div
                          className={`status ${
                            user.theory.subscribe.pay
                              ? "status_success"
                              : "status_failure"
                          }`}
                        >
                          {user.theory.subscribe.pay ? "Оплачен" : "Не оплачен"}
                        </div>
                      </div>
                    )}
                    {user.practice?.subscribe && (
                      <div className="status__wrapper">
                        <div
                          className={`status ${
                            user.practice.subscribe.pay
                              ? "status_success"
                              : "status_failure"
                          }`}
                        >
                          {user.practice.subscribe.pay
                            ? "Оплачен"
                            : "Не оплачен"}
                        </div>
                      </div>
                    )}
                    {user.exam?.subscribe && (
                      <div className="status__wrapper">
                        <div
                          className={`status ${
                            user.exam.subscribe.pay
                              ? "status_success"
                              : "status_failure"
                          }`}
                        >
                          {user.exam.subscribe.pay ? "Оплачен" : "Не оплачен"}
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td className="table__cell">
                  <div className="cell__wrapper">
                    {user.theory?.subscribe && (
                      <div className="status__wrapper">
                        {user.theory.subscribe.key ? (
                          <div className="access-key">
                            <img
                              className="access-key__copy"
                              src={require("../assets/copy.png")}
                              alt="Скопировать"
                              onClick={() =>
                                copyToClipboard(user.theory.subscribe.key)
                              }
                            ></img>
                            <span className="access-key__key">
                              {user.theory.subscribe.key}
                            </span>
                          </div>
                        ) : (
                          <span className="access-key__plug">∗∗∗∗∗</span>
                        )}
                      </div>
                    )}
                    {user.practice?.subscribe && (
                      <div className="status__wrapper">
                        {user.practice.subscribe.key ? (
                          <div className="access-key">
                            <img
                              className="access-key__copy"
                              src={require("../assets/copy.png")}
                              alt="Скопировать"
                              onClick={() =>
                                copyToClipboard(user.practice.subscribe.key)
                              }
                            ></img>
                            <span className="access-key__key">
                              {user.practice.subscribe.key}
                            </span>
                          </div>
                        ) : (
                          <span className="access-key__plug">∗∗∗∗∗</span>
                        )}
                      </div>
                    )}
                    {user.exam?.subscribe && (
                      <div className="status__wrapper">
                        {user.exam.subscribe.key ? (
                          <div className="access-key">
                            <img
                              className="access-key__copy"
                              src={require("../assets/copy.png")}
                              alt="Скопировать"
                              onClick={() =>
                                copyToClipboard(user.exam.subscribe.key)
                              }
                            ></img>
                            <span className="access-key__key">
                              {user.exam.subscribe.key}
                            </span>
                          </div>
                        ) : (
                          <span className="access-key__plug">∗∗∗∗∗</span>
                        )}
                      </div>
                    )}
                  </div>
                </td>
                <td className="table__cell table__cell_centered table__cell_result">
                  <div className="cell__wrapper">
                    {user.theory?.best ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.theory.best >= 85
                                ? "result__count_success"
                                : user.theory.best >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.theory.best}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && (
                          <span className="result__type">Тестирование</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 1 || course.id === 5) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && (
                            <span className="result__type">Тестирование</span>
                          )}
                        </div>
                      )
                    )}
                    {user.practice?.best ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.practice.best >= 85
                                ? "result__count_success"
                                : user.practice.best >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.practice.best}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && (
                          <span className="result__type">Тренажер</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 3 ||
                        course.id === 5 ||
                        course.id === 11) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && (
                            <span className="result__type">Тренажер</span>
                          )}
                        </div>
                      )
                    )}
                    {user.exam?.best ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.exam.best >= 85
                                ? "result__count_success"
                                : user.exam.best >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.exam.best}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && course.id === 7 && (
                          <span className="result__type">Экзамен</span>
                        )}
                        {course.ids.length > 1 && course.id === 11 && (
                          <span className="result__type">Теория (продв.)</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 7 || course.id === 9) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && course.id === 7 && (
                            <span className="result__type">Экзамен</span>
                          )}
                          {course.ids.length > 1 && course.id === 11 && (
                            <span className="result__type">
                              Теория (продв.)
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </td>
                <td className="table__cell table__cell_centered table__cell_result">
                  <div className="cell__wrapper">
                    {user.theory?.last ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.theory.last >= 85
                                ? "result__count_success"
                                : user.theory.last >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.theory.last}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && (
                          <span className="result__type">Тестирование</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 1 || course.id === 5) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && (
                            <span className="result__type">Тестирование</span>
                          )}
                        </div>
                      )
                    )}
                    {user.practice?.last ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.practice.last >= 85
                                ? "result__count_success"
                                : user.practice.last >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.practice.last}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && (
                          <span className="result__type">Тренажер</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 3 || course.id === 5 || course.id === 11) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && (
                            <span className="result__type">Тренажер</span>
                          )}
                        </div>
                      )
                    )}
                    {user.exam?.last ? (
                      <div className="result">
                        <div className="result__score">
                          <span
                            className={`result__count ${
                              user.exam.last >= 85
                                ? "result__count_success"
                                : user.exam.last >= 75
                                ? "result__count_warning"
                                : "result__count_failed"
                            }`}
                          >
                            {user.exam.last}
                          </span>
                          <span className="result__preposition">из</span>
                          <span className="result__count result__count_total">
                            100
                          </span>
                        </div>
                        {course.ids.length > 1 && course.id === 7 && (
                          <span className="result__type">Экзамен</span>
                        )}
                        {course.ids.length > 1 && course.id === 11 && (
                          <span className="result__type">Теория (продв.)</span>
                        )}
                      </div>
                    ) : (
                      (course.id === 7 || course.id === 9) && (
                        <div className="result">
                          <div className="result__score">-</div>
                          {course.ids.length > 1 && course.id === 7 && (
                            <span className="result__type">Экзамен</span>
                          )}
                          {course.ids.length > 1 && course.id === 11 && (
                            <span className="result__type">
                              Теория (продв.)
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </td>
                <td className="table__cell table__cell_centered">
                  <div className="cell__wrapper">
                    {user.theory?.date ? (
                      <div
                        className={`date ${
                          course.ids.length > 1 ? "date__full" : ""
                        }`}
                      >
                        <span>{user.theory.date}</span>
                        <div className="status status_success">Пройден</div>
                      </div>
                    ) : (
                      (course.id === 1 || course.id === 5) && (
                        <div
                          className={`date ${
                            course.ids.length > 1 ? "date__full" : ""
                          }`}
                        >
                          <div className="status status_failure">
                            Не пройден
                          </div>
                        </div>
                      )
                    )}
                    {user.practice?.date ? (
                      <div
                        className={`date ${
                          course.ids.length > 1 ? "date__full" : ""
                        }`}
                      >
                        <span>{user.practice.date}</span>
                        <div className="status status_success">Пройден</div>
                      </div>
                    ) : (
                      (course.id === 3 || course.id === 5 || course.id === 11) && (
                        <div
                          className={`date ${
                            course.ids.length > 1 ? "date__full" : ""
                          }`}
                        >
                          <div className="status status_failure">
                            Не пройден
                          </div>
                        </div>
                      )
                    )}
                    {user.exam?.date ? (
                      <div
                        className={`date ${
                          course.ids.length > 1 ? "date__full" : ""
                        }`}
                      >
                        <span>{user.exam.date}</span>
                        <div className="status status_success">Пройден</div>
                      </div>
                    ) : (
                      (course.id === 7 || course.id === 9) && (
                        <div
                          className={`date ${
                            course.ids.length > 1 ? "date__full" : ""
                          }`}
                        >
                          <div className="status status_failure">
                            Не пройден
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </td>
                <td className="table__cell table__cell_centered">
                  <div className="cell__wrapper">
                    {(course.id === 1 || course.id === 5) && (
                      <div className="counter">{user.theory?.attempts}</div>
                    )}
                    {(course.id === 3 ||
                      course.id === 5 ||
                      course.id === 7 ||
                      course.id === 11) && (
                      <div className="counter">{user.practice?.attempts}</div>
                    )}
                    {(course.id === 7 ||
                      course.id === 9 ||
                      course.id === 11) && (
                      <div className="counter">{user.exam?.attempts}</div>
                    )}
                  </div>
                </td>
                <td className="table__cell">
                  <button>
                    <img
                      className="remove"
                      src={require("../assets/remove.png")}
                      alt="Удалить"
                      onClick={() => showDeleteModal(user.employee_id)}
                    ></img>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <footer className={`footer ${isCheck.length > 0 ? "footer_active" : ""}`}>
        <div className="footer__buttons">
          <button
            className="new-employee-button pay"
            onClick={() => setPayUsersModalActive(true)}
          >
            Оплатить
          </button>
          <button
            className="button button_additional"
            onClick={() => setDeleteUsersModalActive(true)}
          >
            Удалить
          </button>
        </div>
      </footer>
      <Modal active={addUserModalActive} setActive={setAddUserModalActive}>
        <div className="users-add">
          <div className="users-inputs">
            <input
              className="search users-search"
              placeholder="Найти сотрудника"
              onChange={(e) => filterAllUsers(e.target.value)}
            ></input>
            <ul className="users-list">
              {filteredAllUsers?.map((user) => (
                <label key={user.employee_id}>
                  <li className="users-item">
                    <input
                      type="checkbox"
                      onChange={() => addUser(user.employee_id)}
                    ></input>
                    <span>{`${user.last_name} ${user.first_name}`}</span>
                  </li>
                </label>
              ))}
            </ul>
          </div>
          <button className="new-employee-button" onClick={submitAddUser}>
            Добавить сотрудников
          </button>
        </div>
      </Modal>
      <Modal
        active={deleteUserModalActive}
        setActive={setDeleteUserModalActive}
      >
        <div className="confirm">
          <p className="confirm__text">Вы уверены?</p>
          <div className="confirm__buttons">
            <button
              className="confirm__button confirm__button_accept"
              onClick={deleteUserHandler}
            >
              Да
            </button>
            <button
              className="confirm__button confirm__button_decline"
              onClick={() => setDeleteUserModalActive(false)}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        active={deleteUsersModalActive}
        setActive={setDeleteUsersModalActive}
      >
        <div className="confirm">
          <p className="confirm__text">Вы уверены?</p>
          <div className="confirm__buttons">
            <button
              className="confirm__button confirm__button_accept"
              onClick={deleteUsersHandler}
            >
              Да
            </button>
            <button
              className="confirm__button confirm__button_decline"
              onClick={() => setDeleteUsersModalActive(false)}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>
      <Modal active={payUsersModalActive} setActive={setPayUsersModalActive}>
        <div className="confirm">
          <p className="confirm__text">Вы уверены?</p>
          <div className="confirm__buttons">
            <button
              className="confirm__button confirm__button_accept"
              onClick={payUsersHandler}
            >
              Да
            </button>
            <button
              className="confirm__button confirm__button_decline"
              onClick={() => setPayUsersModalActive(false)}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default CoursePage;
