import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { logout } from "../../redux/slices/auth";

import "./Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    window.localStorage.removeItem("token");
  };

  return (
    <div className="navbar">
      <aside>
        <nav className="nav">
          <ul className="nav__list">
            <li className="nav__item">
              <Link className="logo" to={"/courses"}>
                <img
                  className="logo__image"
                  src={require("../../assets/logo.png")}
                  alt="VINPIN Академия"
                  title="VINPIN Академия"
                ></img>
              </Link>
            </li>
            <li className="nav__item">
              <NavLink
                to={"/courses"}
                className={({ isActive }) =>
                  isActive
                    ? "nav__link nav-courses-active nav__link_active"
                    : "nav__link nav-courses"
                }
              >
                Курсы
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to={"/users"}
                className={({ isActive }) =>
                  isActive
                    ? "nav__link nav-users-active nav__link_active"
                    : "nav__link nav-users"
                }
              >
                Сотрудники
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to={"/pay"}
                className={({ isActive }) =>
                  isActive
                    ? "nav__link nav-payment-active nav__link_active"
                    : "nav__link nav-payment"
                }
              >
                Оплата
              </NavLink>
            </li>
          </ul>
          <button className="nav__logout" onClick={logoutHandler}>
            <img src={require("../../assets/logout.png")} alt="Выйти"></img>
          </button>
        </nav>
      </aside>
    </div>
  );
};

export default Navbar;
